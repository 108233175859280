<template>
  <div class="mobile-wrap">
    <div class="form-item">
      <MyField v-model="state.old_mobile" type="number" maxlength="11" label="原手机号码" placeholder="请输入原手机号码" />
    </div>
    <div class="form-item">
      <MyField v-model="state.new_mobile" type="number" maxlength="11" label="新手机号码" placeholder="请新入原手机号码" />
    </div>
    <div class="form-item">
      <MyField v-model="state.captcha" type="number" maxlength="6" label="验证码" placeholder="请输入验证码">
        <div>
          <div class="vertifiy-wrap" v-if="!isCountDown" @click="vertifyHandle">
            <span class="line">|</span>
            <span>获取验证码</span>
          </div>
          <div class="my-countdown" v-else>
            <span class="line">|</span>
            <CountDown @finish="finishHandle" :time="time" format ="ss"/>
            <div class="secends">s</div>
          </div>
        </div>
      </MyField>
    </div>
    <div class="btn-wrap" @click="updateHandle">更新</div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { CountDown, Toast } from 'vant'
import MyField from '@/components/my-input'
import { getCaptcha, putUserMobile } from '@/api/'
import { useRouter } from 'vue-router'
export default {
  components: {
    MyField,
    CountDown
  },
  setup () {
    const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    const isCountDown = ref(false)
    const time = ref(60 * 1000)
    const state = reactive({
      old_mobile: '',
      new_mobile: '',
      captcha: ''
    })
    const router = useRouter()

    const vertifyHandle = () => {
      if (reg.test(state.new_mobile)) {
        isCountDown.value = true
        getCaptcha({
          mobile: state.new_mobile
        }).then(res => {
          console.log(res)
        })
      } else {
        Toast('请输入正确的手机号码')
      }
    }

    const finishHandle = () => {
      isCountDown.value = false
    }

    const updateHandle = () => {
      if (!reg.test(state.old_mobile)) {
        Toast('原手机号码格式错误')
      } else if (!reg.test(state.new_mobile)) {
        Toast('新手机号码格式错误')
      } else if (state.captcha === '') {
        Toast('请输入验证码')
      } else {
        putUserMobile(state).then(res => {
          console.log(res)
          if (res.code === 9) {
            Toast.fail(res.message)
          }
          if (res.code === 200) {
            Toast.success({
              message: '修改成功',
              onClose: () => {
                router.back()
              }
            })
          }
        })
      }
    }

    return {
      isCountDown,
      state,
      time,
      vertifyHandle,
      finishHandle,
      updateHandle
    }
  }
}
</script>

<style lang="less" scoped>
.mobile-wrap {
  width: 100%;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  padding: 26px 38px 0 38px;
  .form-item {
    margin-bottom: 36px;
  }
  .vertifiy-wrap {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #007FFF;
    white-space: nowrap;
    .line {
      color: #B9C6D3;
      margin-right: 8px;
    }
  }
  .my-countdown {
    width: 81px;
    display: flex;
    justify-content: center;
    .line {
      color: #B9C6D3;
      margin-right: 8px;
      font-size: 14px;
    }
    .secends {
      color: #B9C6D3;
      margin-left: 4px;
      font-size: 14px;
    }
  }
  .btn-wrap {
    height: 44px;
    background: #007AFF;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
  }
}
</style>
